<template>
  <component :is="crypto === null ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="crypto === null"
    >
      <h4 class="alert-heading">
        اطلاعات دریافت نشد
      </h4>
      <div class="alert-body">
        این رمز ارز با این ای دی یافت نشد
        <b-link
          class="alert-link"
          :to="{ name: 'cryptocurrency'}"
        >
          لیست رمز ارزها
        </b-link>
        برای دیگر رمز ارزها.
      </div>
    </b-alert>

    <user-edit-tab-account
      :crypto-data="crypto"
      class="mt-2 pt-75"
      @rested="restedForm"
      @submited="submitFrom"
    />

  </component>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRow,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import UserEditTabAccount from './CryptoEditPage.vue'

export default {
  name: 'EditCrypto',
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRow,
    BAlert,
    BLink,
    UserEditTabAccount,
  },
  data() {
    return {
      crypto: {},
    }
  },
  async mounted() {
    await this.getCrypto()
  },
  methods: {
    async getCrypto() {
      const response = await this.$axios(`/crypto/${this.$route.params.id}`)
      this.crypto = response.data.data
    },
    async submitFrom() {
      const data = new FormData()
      data.append('english_name', this.crypto.english_name)
      data.append('persian_name', this.crypto.persian_name)
      data.append('symbol', this.crypto.symbol)
      data.append('purchase_price_from_us', this.crypto.purchase_price_from_us)
      data.append('sale_price_from_us', this.crypto.sale_price_from_us)
      data.append('wallet_address', this.crypto.wallet_address)
      data.append('inventory', this.crypto.inventory)
      data.append('status', this.crypto.status)
      // data.append('image', this.crypto.image)
      data.append('wage', this.crypto.wage)
      // data.append('type', this.crypto.type)

      const response = await this.$axios({
        url: `/crypto/edit/${this.$route.params.id}`,
        method: 'POST',
        data,
      })

      console.log(response)
    },
    restedForm() {
      this.getCrypto()
    },
  },
}
</script>

<style scoped>

</style>
