<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="cryptoData.image"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ cryptoData.english_name }}
      </h4>
      <small class="text-muted">
        {{ cryptoData.symbol }}
      </small>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
          >
          <span class="d-none d-sm-inline">ارتقایی تصویر</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <!--        <b-button-->
        <!--          variant="outline-secondary"-->
        <!--          class="ml-1"-->
        <!--        >-->
        <!--          <span class="d-none d-sm-inline">Remove</span>-->
        <!--          <feather-icon-->
        <!--            icon="TrashIcon"-->
        <!--            class="d-inline d-sm-none"-->
        <!--          />-->
        <!--        </b-button>-->
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form
      @submit="onSubmit"
      @reset="onReset"
    >
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="نام انگلیسی"
            label-for="english_name"
          >
            <b-form-input
              id="english_name"
              v-model="cryptoData.english_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="نام فارسی"
            label-for="persian_name"
          >
            <b-form-input
              id="persian_name"
              v-model="cryptoData.persian_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="سمبل ارز"
            label-for="symbol"
          >
            <b-form-input
              id="symbol"
              v-model="cryptoData.symbol"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="قیمت خرید از ما"
            label-for="purchase_price_from_us"
          >
            <b-form-input
              id="purchase_price_from_us"
              v-model="cryptoData.purchase_price_from_us"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="قیمت فروش به ما"
            label-for="sale_price_from_us"
          >
            <b-form-input
              id="persian_name"
              v-model="cryptoData.sale_price_from_us"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="موجودی"
            label-for="inventory"
          >
            <b-form-input
              id="inventory"
              v-model="cryptoData.inventory"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="کارمزد شبکه"
            label-for="wage"
          >
            <b-form-input
              id="wage"
              v-model="cryptoData.wage"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="آدرس کیف پول"
            label-for="wallet_address"
          >
            <b-form-input
              id="wage"
              v-model="cryptoData.wallet_address"
              type="text"
            />
          </b-form-group>
        </b-col>

        <!--         Field: Status-->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="وضعیت نمایش"-->
        <!--            label-for="status"-->
        <!--          >-->
        <!--            <v-select-->
        <!--              v-model="cryptoData.status"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="statusOptions"-->
        <!--              :clearable="false"-->
        <!--              input-id="status"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

      </b-row>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        type="submit"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        ثبت تغییرات
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        پاک کردن
      </b-button>

    </b-form>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
// import { avatarText } from '@core/utils/filter'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// import { ref } from '@vue/composition-api'
// import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    cryptoData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      cryptoEditPage: null,
      statusOptions: [
        { value: 0, text: 'غیر فعال' },
        { value: 1, text: 'فعال' },
      ],
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$emit('submited')
    },
    onReset(event) {
      event.preventDefault()
      this.$emit('rested')
    },
    uploadImage() {
      console.log('clicked')
    },
  },
  // setup(props) {
  //   const { resolveUserRoleVariant } = useUsersList()
  //
  //   const roleOptions = [
  //     { label: 'Admin', value: 'admin' },
  //     { label: 'Author', value: 'author' },
  //     { label: 'Editor', value: 'editor' },
  //     { label: 'Maintainer', value: 'maintainer' },
  //     { label: 'Subscriber', value: 'subscriber' },
  //   ]
  //
  //   const statusOptions = [
  //     { label: 'Pending', value: 'pending' },
  //     { label: 'Active', value: 'active' },
  //     { label: 'Inactive', value: 'inactive' },
  //   ]
  //
  //   const permissionsData = [
  //     {
  //       module: 'Admin',
  //       read: true,
  //       write: false,
  //       create: false,
  //       delete: false,
  //     },
  //     {
  //       module: 'Staff',
  //       read: false,
  //       write: true,
  //       create: false,
  //       delete: false,
  //     },
  //     {
  //       module: 'Author',
  //       read: true,
  //       write: false,
  //       create: true,
  //       delete: false,
  //     },
  //     {
  //       module: 'Contributor',
  //       read: false,
  //       write: false,
  //       create: false,
  //       delete: false,
  //     },
  //     {
  //       module: 'User',
  //       read: false,
  //       write: false,
  //       create: false,
  //       delete: true,
  //     },
  //   ]
  //
  //   // ? Demo Purpose => Update image on click of update
  //   const refInputEl = ref(null)
  //   const previewEl = ref(null)
  //
  //   const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
  //     // eslint-disable-next-line no-param-reassign
  //     props.userData.avatar = base64
  //   })
  //
  //   return {
  //     resolveUserRoleVariant,
  //     avatarText,
  //     roleOptions,
  //     statusOptions,
  //     permissionsData,
  //
  //     //  ? Demo - Update Image on click of update button
  //     refInputEl,
  //     previewEl,
  //     inputImageRenderer,
  //   }
  // },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
